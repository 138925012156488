// import React, { useEffect } from 'react';

// function AppleSignIn() {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
//     script.async = true;

//     script.onload = () => {
//       if (window.AppleID) {
//         window.AppleID.auth.init({
//           clientId: 'com.rallytraders.RallyWeb', // Replace with your client ID
//           scope: 'name email',
//           redirectURI: 'https://www.rallytraders.com/account', // Replace with your redirect URI
//           usePopup: true
//         });
//       }
//     };

//     document.body.appendChild(script);

//     const handleSuccess = (event) => {
//       const { authorization } = event.detail;
//       console.log('Apple Sign-In Success:', authorization);
//       // Send authorization.code to your server for verification
//     };

//     const handleFailure = (event) => {
//       console.error('Apple Sign-In Error:', event.detail);
//     };

//     window.addEventListener('AppleIDSignInOnSuccess', handleSuccess);
//     window.addEventListener('AppleIDSignInOnFailure', handleFailure);

//     return () => {
//       window.removeEventListener('AppleIDSignInOnSuccess', handleSuccess);
//       window.removeEventListener('AppleIDSignInOnFailure', handleFailure);
//     };
//   }, []);

//   return (
//     <div>
//       <div
//         id="appleid-signin"
//         data-color="white"
//         data-border="false"
//         data-type="sign in"
//         style={{ width: '200px', height: '40px' }}
//       ></div>
//     </div>
//   );
// }

// export default AppleSignIn;

import React, { useEffect } from 'react';

function AppleSignIn() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;
    script.onload = () => {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: 'com.rallytraders.RallyWeb', // Replace with your own client ID
          scope: 'name email',
          redirectURI: 'https://www.rallytraders.com/account', // Replace with your redirect URI
          usePopup: true, // Set to true if you prefer the popup experience
        });
      }
    };
    
    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
        console.log("success")
        console.log(event);
    });
    
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
         console.log("failure")
         console.log(event);
    });

    document.body.appendChild(script);
  }, []);

  return (
    <div
      id="appleid-signin"
      data-color="white"
      style={{ width: '200px', height: '40px' }}
    />
  );
};

export default AppleSignIn;
